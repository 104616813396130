// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, change you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.


//
// Bring in Bootstrap
//

// Option 1
//
// Import all of Bootstrap's CSS

// @import "../bootstrap-4.6.2/scss/bootstrap";

// Option 2
//
// Place variable overrides first, then import just the styles you need. Note that some stylesheets are required no matter what.

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

// Customize some defaults to use Mac OS colors https://developer.apple.com/design/human-interface-guidelines/foundations/color/#specifications
$white: #FFF;
$blue:    #0A84FF;
$indigo:  #5E5CE6;
$purple:  #BF5AF2;
$pink:    #FF375F;
$red:     #FF453A;
$orange:  #FF9F0A;
$yellow:  #FFD60A;
$green:   #30D158;
$teal:    #40C8E0;
$cyan:    #64D2FF;

$gray-100: #F2F2F7;
$gray-200: #E5E5EA;
$gray-300: #D1D1D6;
$gray-400: #C7C7CC;
$gray-500: #8E8E93;
$gray-600: #48484A;
$gray-700: #3A3A3C;
$gray-800: #2C2C2E;
$gray-900: #1C1C1E;

$body-bg:       $white;

// Secondary button background
$secondary:     $gray-400;

$jumbotron-bg:  $gray-200;

// Match button and list group color
$navbar-light-color:                $gray-500;
$navbar-light-hover-color:          $gray-700;
$navbar-light-active-color:         $gray-900;
$navbar-light-disabled-color:       $gray-500;

@import "../bootstrap-4.6.2/scss/functions"; // Required
@import "../bootstrap-4.6.2/scss/variables"; // Required
@import "../bootstrap-4.6.2/scss/mixins"; // Required

@import "../bootstrap-4.6.2/scss/root"; // Required
@import "../bootstrap-4.6.2/scss/reboot"; // Required
@import "../bootstrap-4.6.2/scss/type";
// @import "../bootstrap-4.6.2/scss/images";
// @import "../bootstrap-4.6.2/scss/code";
@import "../bootstrap-4.6.2/scss/grid";
@import "../bootstrap-4.6.2/scss/tables";
// @import "../bootstrap-4.6.2/scss/forms";
@import "../bootstrap-4.6.2/scss/buttons";
@import "../bootstrap-4.6.2/scss/transitions";
// @import "../bootstrap-4.6.2/scss/dropdown";
// @import "../bootstrap-4.6.2/scss/button-group";
// @import "../bootstrap-4.6.2/scss/input-group"; // Requires forms
// @import "../bootstrap-4.6.2/scss/custom-forms";
@import "../bootstrap-4.6.2/scss/nav";
@import "../bootstrap-4.6.2/scss/navbar"; // Requires nav
@import "../bootstrap-4.6.2/scss/card";
// @import "../bootstrap-4.6.2/scss/breadcrumb";
// @import "../bootstrap-4.6.2/scss/pagination";
// @import "../bootstrap-4.6.2/scss/badge";
@import "../bootstrap-4.6.2/scss/jumbotron";
// @import "../bootstrap-4.6.2/scss/alert";
// @import "../bootstrap-4.6.2/scss/progress";
// @import "../bootstrap-4.6.2/scss/media";
// @import "../bootstrap-4.6.2/scss/list-group";
@import "../bootstrap-4.6.2/scss/close";
// @import "../bootstrap-4.6.2/scss/toasts";
@import "../bootstrap-4.6.2/scss/modal"; // Requires transitions
// @import "../bootstrap-4.6.2/scss/tooltip";
// @import "../bootstrap-4.6.2/scss/popover";
// @import "../bootstrap-4.6.2/scss/carousel";
// @import "../bootstrap-4.6.2/scss/spinners";
@import "../bootstrap-4.6.2/scss/utilities";
// @import "../bootstrap-4.6.2/scss/print";


//
// Custom styles
//
// Default text color
.text {
    color: $gray-900;
}

// Ensure card body and jumbotron background match
.card-header { background-color:   $gray-200; }
// Ligher card body to ensure black text stands out
.card-body { background-color: $gray-200;}
.card-footer { background-color:   $gray-200; }

// Sets the background of the nav bar
.bg-light {
    background-color: $white !important; 
}

// Used for alternating grid rows on the ephemeris
.grid-row-odd {
    background-color: $gray-300;
}
.grid-row-even {
    background-color: $gray-100;
}


.btn-outline-secondary { 
    border-color: $gray-800; 
    color: $gray-500;
}

.list-group { 
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.list-group-item-light { 
    color: $gray-600; 
}

.jumbotron { 
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.card { 
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn:not(.dropdown-item) {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.15s ease;
}

// Default text color
.text {
    color: $black;
}

// Elm datepicker control
.elm-datepicker--container {
    position: relative; 
}
  
.elm-datepicker--input:focus {
    outline: 0; 
}
  
.elm-datepicker--picker {
    position: absolute;
    border: 1px solid #CCC;
    z-index: 10;
    background-color: white; 
}
  
.elm-datepicker--picker-header,
.elm-datepicker--weekdays {
    background: #F2F2F2; 
}
  
.elm-datepicker--picker-header {
    display: flex;
    align-items: center; 
}
  
.elm-datepicker--prev-container,
.elm-datepicker--next-container {
    flex: 0 1 auto;
    cursor: pointer; 
}
  
.elm-datepicker--month-container {
    flex: 1 1 auto;
    padding: 0.5em;
    display: flex;
    flex-direction: column; 
}
  
.elm-datepicker--month,
.elm-datepicker--year {
    flex: 1 1 auto;
    cursor: default;
    text-align: center; 
}
  
.elm-datepicker--year {
    font-size: 0.6em;
    font-weight: 700; 
}
  
.elm-datepicker--prev,
.elm-datepicker--next {
    border: 6px solid transparent;
    background-color: inherit;
    display: block;
    width: 0;
    height: 0;
    padding: 0 0.2em; 
}
  
.elm-datepicker--prev {
    border-right-color: #AAA; 
}

.elm-datepicker--prev:hover {
    border-right-color: #BBB; 
}
  
.elm-datepicker--next {
    border-left-color: #AAA; 
}

.elm-datepicker--next:hover {
    border-left-color: #BBB; 
}
  
.elm-datepicker--table {
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 0.8em; 
}

.elm-datepicker--table td {
    width: 2em;
    height: 2em;
    text-align: center; 
}
  
.elm-datepicker--row {
    border-top: 1px solid #F2F2F2; 
}
  
.elm-datepicker--dow {
    border-bottom: 1px solid #CCC;
    cursor: default; 
}
  
.elm-datepicker--day {
    cursor: pointer; 
}

.elm-datepicker--day:hover {
      background: #F2F2F2; 
}
  
.elm-datepicker--disabled {
    cursor: default;
    color: #DDD; 
}

.elm-datepicker--disabled:hover {
      background: inherit; 
}
  
.elm-datepicker--picked {
    color: white;
    background: darkblue; 
}

.elm-datepicker--picked:hover {
      background: darkblue; 
}
  
.elm-datepicker--today {
    font-weight: bold; 
}
  
.elm-datepicker--other-month {
    color: #AAA; 
}
.elm-datepicker--other-month.elm-datepicker--disabled {
      color: #EEE; 
}
.elm-datepicker--other-month.elm-datepicker--picked {
      color: white; 
}
